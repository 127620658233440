import Vue from 'vue'
import Vuex from 'vuex'
import websites from './websites'
import errors from './errors'
import versionUpdate from './versionUpdate'
import userRoles from './userRoles'
import appState from './appState'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    websites,
    errors,
    versionUpdate,
    userRoles,
    appState
  }
})
