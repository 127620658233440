var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{class:[
    _vm.$route.name == 'login' ||
    _vm.$route.name == 'forgotPassword' ||
    _vm.$route.name == 'resetPassword' ||
    _vm.$route.name == 'activateAccount'
      ? 'appColor'
      : '',
  ],attrs:{"light":"","id":"app"}},[((_vm.loggedIn && _vm.$route.name != 'login') || _vm.loggingOut)?_c('div',[_c('sidebar-left'),_c('transition',{attrs:{"name":"fade"}},[_c('app-header',{attrs:{"loggingOut":_vm.loggingOut},on:{"logOut":function($event){return _vm.logOut()}}})],1),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('transition',{attrs:{"name":"fade"}},[_c('router-view',{key:_vm.$route.params.id})],1)],1)],1),_c('app-versionupdatesnackbars'),_c('app-onboarding',{ref:"onboarding"}),_c('app-reportingerror'),_c('app-footer'),(_vm.error)?_c('errorHandeling',{attrs:{"snackbarText":_vm.error,"buttons":[
        {
          isText: true,
          functionName: 'reportError',
          text: 'Report error',
        },
        {
          isText: true,
          functionName: 'clearError',
          text: 'Close',
        },
      ],"snackbarColor":"error","snackbarTimout":"-1","snackbarIcon":"mdi-alert"},on:{"clearError":function($event){return _vm.$store.dispatch('resetError')}}}):_vm._e()],1):(!_vm.loggedIn && _vm.$route.name == 'login')?_c('v-container',{staticClass:"max-w-login-container",attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',[_c('transition',{attrs:{"name":"fade"}},[_c('login')],1)],1)],1)],1):(!_vm.loggedIn)?_c('v-container',{staticClass:"max-w-login-container",attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',[_c('transition',{attrs:{"name":"fade"}},[_c('router-view',{key:_vm.$route.params.id})],1)],1)],1)],1):_vm._e(),_c('sidebar-right',{ref:"sidebarRight"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }