<template>
  <v-app
    light
    id="app"
    :class="[
      $route.name == 'login' ||
      $route.name == 'forgotPassword' ||
      $route.name == 'resetPassword' ||
      $route.name == 'activateAccount'
        ? 'appColor'
        : '',
    ]"
  >
    <div v-if="(loggedIn && $route.name != 'login') || loggingOut">
      <sidebar-left />
      <transition name="fade">
        <app-header @logOut="logOut()" :loggingOut="loggingOut" />
      </transition>

      <v-main>
        <v-container fluid>
          <transition name="fade">
            <router-view :key="$route.params.id"></router-view>
          </transition>
        </v-container>
      </v-main>

      <app-versionupdatesnackbars />

      <app-onboarding ref="onboarding" />
      <app-reportingerror />
      <app-footer />

      <errorHandeling
        v-if="error"
        :snackbarText="error"
        :buttons="[
          {
            isText: true,
            functionName: 'reportError',
            text: 'Report error',
          },
          {
            isText: true,
            functionName: 'clearError',
            text: 'Close',
          },
        ]"
        snackbarColor="error"
        snackbarTimout="-1"
        snackbarIcon="mdi-alert"
        @clearError="$store.dispatch('resetError')"
      />
    </div>

    <v-container
      v-else-if="!loggedIn && $route.name == 'login'"
      class="max-w-login-container"
      fill-height
      fluid
    >
      <v-row align="center" justify="center">
        <v-col>
          <transition name="fade">
            <login></login>
          </transition>
        </v-col>
      </v-row>
    </v-container>

    <v-container
      v-else-if="!loggedIn"
      class="max-w-login-container"
      fill-height
      fluid
    >
      <v-row align="center" justify="center">
        <v-col>
          <transition name="fade">
            <router-view :key="$route.params.id"></router-view>
          </transition>
        </v-col>
      </v-row>
    </v-container>
    <sidebar-right ref="sidebarRight" />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      userIsAdministrator: this.$auth.userIsAdministrator(),
      userIsUserManager: this.$auth.userIsUserManager(),
      loggedIn: this.$auth.loggedIn(),
      loggingOut: false,
      websiteName: this.$config.websiteName,
    };
  },
  created() {
    this.$auth.onChange = (loggedIn) => {
      this.loggedIn = loggedIn;
      this.userIsAdministrator = this.$auth.userIsAdministrator();
      this.userIsUserManager = this.$auth.userIsUserManager();

      if (this.loggedIn) {
        this.$store.dispatch("getOnboardingItems");
        this.getWebsites();
      }
    };

    if (this.loggedIn) {
      this.$store.dispatch("getOnboardingItems");
      this.getWebsites();
      if (this.userIsAdministrator) {
        this.$request.get("/update-required", null, (result) => {
          //view orange snackbar for update
          if (result.data == true) {
            this.$store.dispatch("setUpdateSnackbar", true);
          } else {
            this.$store.dispatch("setError", result);
          }
        });
      }
    }
    document.title = this.$config.websiteName;
  },

  computed: {
    ...mapGetters({
      websites: "allWebsites",
      selectedWebsite: "selectedWebsite",
      error: "error",
      isReporting: "isReporting",
      errorDialog: "errorDialog",
      updateSnackbar: "updateSnackbar",
      updateVersionSnackbar: "isSuccessfullyUpdated",
      isNavigating: "isNavigating",
    }),

    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },

    pageTitle() {
      return document.title;
    },
  },
  methods: {
    logOut() {
      this.loggingOut = true;
      this.$auth.logOut((res) => {
        this.loggingOut = false;

        if (!res.success) {
          this.$store.dispatch("setError", res);
        } else {
          this.$router.push({ name: "login" });
          this.$router.go(1);
        }
      });
    },

    getWebsites() {
      if (this.websites.length == 0) {
        this.$request.get("/websites", null, (res) => {
          if (res.success) {
            this.$store.dispatch("setAllWebsites", res.data);
            this.$store.dispatch("setSelectedWebsite", this.websites[0]);
            if (
              this.$route.path == "/" &&
              this.loggedIn &&
              !this.isNavigating
            ) {
              this.$router.replace({
                name: "editPage",
                params: { id: this.selectedWebsite.DashboardPageId.$oid },
              });
              this.$router.go(1);
            }
          } else {
            this.$store.dispatch("setError", res);
          }
        });
      } else {
        this.$store.dispatch("setSelectedWebsite", this.websites[0]);
        if (this.websites.length == 1) {
          this.$store.dispatch("setSelectedWebsiteById", this.websites[0]._id);
          //this.setSelectedWebsite(this.websites[0]._id);
        }
      }
    },
  },

  metaInfo() {
    return {
      title: this.pageTitle,
      /* meta: [
        {
          name: "description",
          content: "My page description",
        },
      ], */
    };
  },
};
</script>

<style>
a {
  text-decoration: none;
}
.router-link-active {
  font-weight: 500;
}

.router-link-active .v-list-item__subtitle {
  font-weight: bold !important;
}

.fade-enter-active {
  transition-property: opacity;
  transition-duration: 0.3s;
}

.fade-enter-active {
  transition-property: opacity;
  transition-duration: 0.3s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
.max-w-login-container {
  max-width: 750px;
}
.appColor {
  background-color: var(--v-background-base) !important;
}

.row {
  margin: 0;
}

.custom-overview-row {
  border-bottom: 1px solid #ddd;
}

.custom-overview-row.no-border {
  border-bottom: 0px;
}

.custom-overview-row:last-child {
  border-bottom: 0;
}

.border-top {
  border-top: 1px solid #ddd;
}

.custom-overview-row .input-group:not(.no-styling),
.custom-overview-row h4 {
  margin-left: 15px;
  margin-top: 15px;
  padding-right: 30px;
}

.custom-overview-row-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.custom-overview-row-title {
  text-overflow: ellipsis;
  overflow: hidden;
}

.textInput {
  border: 1px solid #e4e4e4;
  width: 100%;
  min-height: 30px;
  padding: 5px;
  outline: none;
}

.textInput:focus {
  outline: none;
}

.pageElementLanguageSelectRichText,
.pageElementLanguageSelect {
  max-width: 65px;
  padding: 0;
}

.add-item-select {
  max-width: 300px;
  float: left;
}

.color-window-onboarding .v-window__prev {
  background: #ffff !important;
  color: #ffff !important;
  margin: 0 0 0 32px;
}
.color-window-onboarding .v-window__next {
  background: #ffff !important;
  color: #ffff !important;
  margin: 0 32px 0 0;
}
.v-data-table-header th {
  white-space: nowrap;
}
@media only screen and (max-width: 568px) {
  .edit-page-action-button {
    width: 100%;
  }

  .edit-page-action-button .btn__content {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-top: 8px;
  }

  .edit-page-action-button .btn__content i {
    padding-top: 0px;
    margin-top: -3px;
    margin-left: 5px;
  }
}

@media only screen and (max-width: 465px) {
  .dropdown-action-button {
    margin: 0;
    position: relative;
    top: -14px;
  }
}
</style>
